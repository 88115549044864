<template>
    <div class="setting-page">
        <a-spin :spinning="loading">
            <div class="ft20 cl-main ftw600">站点资料</div>

            <div class="mt20 bg-w pd40">
                <div style="width: 1000px;">
                    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                        <a-form-model-item required label="站点logo">
                            <div>
                                <div class="ft12 cl-notice">建议上传200x200px</div>
                                <div class="mt12">
                                    <upload-img v-model="form.site_logo"></upload-img>
                                </div>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item required label="站点门牌照">
                            <upload-img v-model="form.door_license_plate"></upload-img>
                        </a-form-model-item>
                        <a-form-model-item required label="站点名称">
                            <a-input v-model="form.name" placeholder="输入站点名称" style="width: 400px;"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="站点简称">
                            <a-input v-model="form.site_abbreviation" placeholder="输入站点简称" style="width: 400px;"></a-input>
                        </a-form-model-item>
                        <a-form-model-item required label="站点电话">
                            <a-input v-model="form.site_phone" placeholder="输入站点电话"
                                     style="width: 400px;"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="营业时间">
                            <a-time-picker :default-value="moment(form.start_time, 'HH:mm:ss')"
                                           @change="(val,dateStrings)=>changeTime(val,dateStrings,'start_time')"/>
                            <a-time-picker :default-value="moment(form.end_time, 'HH:mm:ss')"
                                           @change="(val,dateStrings)=>changeTime(val,dateStrings,'end_time')"/>
                        </a-form-model-item>
                        <a-form-model-item required label="站点地址" prop="province_id">
                            <a-select v-model="form.province_id" style="width: 120px" @change="getRegion" >
                                <a-select-option  v-for="province in provinceData" :key="province.id" data-level="2">
                                    {{ province.name }}
                                </a-select-option>
                            </a-select>

                            <a-select style="width: 120px" v-model="form.city_id" @change="getRegion" placeholder="请选择市">
                                <a-select-option  v-for="city in cityData" :key="city.id" data-level="3">
                                    {{ city.name }}
                                </a-select-option>
                            </a-select>

                            <a-select v-model="form.district_id" style="width: 120px" placeholder="请选择区/县">
                                <a-select-option v-for="district in districtData" :key="district.id">
                                    {{ district.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item required label="地图定位" prop="lat">
                            <a-input-group >
                                <a-row :gutter="8">
                                    <a-col :span="8"><a-input v-model="form.lat" :value="form.lat" addon-before="经度"/></a-col>
                                    <a-col :span="8"><a-input v-model="form.lng" :value="form.lng" addon-before="纬度"/></a-col>
                                </a-row>

                            </a-input-group>
                        </a-form-model-item>

                        <a-form-model-item required label="详细地址" prop="address">
                            <a-input-group >
                                <a-row :gutter="8">
                                    <a-col :span="8"><a-input v-model="form.address" placeholder="请输入详细地址" /></a-col>
                                    <a-col :span="8"><baidu-map :lat="form.lat" :lng="form.lng" :keyword1="form.address" @select="selectAct"></baidu-map></a-col>
                                </a-row>

                            </a-input-group>
                        </a-form-model-item>
                        <a-form-model-item label="站点简介">
                            <html-edit v-model="form.site_intro"></html-edit>
                        </a-form-model-item>
                        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                            <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                                确定保存
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>

                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import uploadImg from '../../components/upload/upload.vue';
import BaiduMap from '../../components/map/baidumap.vue';
import htmlEdit from "@/components/html.vue";
import moment from 'moment';

export default {
    components: {
        htmlEdit,
        uploadImg,
        BaiduMap
    },
    data() {
        return {
            rules:{
                name: [
                    { required: true, message: '请填写站点名称', trigger: 'blur' },
                ],
                site_logo:[
                    { required: true, message: '请上传站点Logo', trigger: 'change' },
                ],
                business_license:[
                    { required: true, message: '营业执照不能为空', trigger: 'change' },
                ],
                site_phone:[
                    { required: true, message: '站点联系电话不能为空', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
                province_id: [
                    { required: true, trigger: 'change',validator:this.checkRegion }
                ],
                lat: [
                    { required: true, message: '请填写经纬度', trigger: 'blur',validator:this.checkLatLng },
                ]
            },
            loading: false,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            dateFormat: 'YYYY-MM-DD',
            form: {
                name:'',//站点名称
                site_abbreviation:'',//站点简称
                site_logo:'',//站点logo
                business_license:'',//营业执照
                door_license_plate:'',
                site_phone:'',//电话号码
                province_id:330000,
                city_id:'',
                district_id:'',
                address:'',
                lat:'',
                lng:'',
                site_intro:'',
                start_time:'09:00:00',
                end_time:'17:00:00',
                business_time:''

            },
            provinceData:[],
            cityData:{},
            districtData:{},
        }
    },
    created() {
        this.loaddata();
    },
    methods: {
        moment,
        loaddata() {
            if(this.loading===true) return;
            this.loading = true;
            this.$http.papi('platform/terminal/showShopDetail',{
                shop_id:this.form.shop_id
            }).then(res=>{
                if(res.detail instanceof Object){
                    if(Object.keys(res.detail).length !== 0){
                        this.form = res.detail;
                        this.form.city_id = this.form.city_id === 0 ? '' :this.form.city_id;
                        this.form.district_id = this.form.district_id === 0 ? '' :this.form.district_id;
                        this.form.province_id = this.form.province_id === 0 ? '' :this.form.province_id;
                        this.getRegion(0,1,true);
                    }

                }
                this.type_list = res.type_list;
                this.account_list = res.account_list;
                this.loading = false;
            }).catch(res=>{
                console.log(res);
                this.loading = false;
                this.$router.go(-1);
            })
        },
        onSubmit() {
            if (this.confirmLoading === true) return;
            this.confirmLoading = true;
            this.$http.papi('platform/terminal/saveShopDetailNews', this.form).then(res => {
                this.$message.success('更新成功', 1, () => {
                    this.confirmLoading = false;
                    this.loaddata();
                })
            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })
        },
        getRegion(value,obj,isInit = false){
            let level = 1;
            if(obj !== 1){
                level = parseInt(obj.data.attrs['data-level']);
            }

            this.$http.api('platform/site/getRegion',{
                level:level,
                parent_id:value,
                province_id: this.form.province_id,
                city_id: this.form.city_id,
                init_id: isInit ? 1 : 0
            }).then(res=>{
                if(level === 1){
                    this.provinceData = res.lists;
                    this.cityData = res.cityData;
                    this.districtData = res.districtData;
                }
                if(level === 2){
                    this.cityData = res.lists;
                    this.form.city_id = null;
                    this.form.district_id = null;
                }
                if(level === 3){
                    this.districtData = res.lists
                }

            }).catch(res=>{
                console.log(res);
            })
        },
        selectAct(e){
            this.form.lat = e.lat;
            this.form.lng = e.lng;
            this.form.address = e.keyword;
            console.log(e.keyword)
        },
        checkRegion(rule, value, callback){
            if (!value) {
                return callback('请选择省份');
            }
            if(!this.form.city_id){
                return callback('请选择城市');
            }
            if(!this.form.district_id){
                return callback('请选择区/县');
            }
            return callback()
        },
        changeTime(date, dateString,type){
            if(type === 'expire_time'){
                this.form.expire_time = dateString;
            }
            if(type === 'start_time'){
                this.form.start_time = dateString;
            }
            if(type === 'end_time'){
                this.form.end_time = dateString;
            }

        },
    }
}
</script>

<style>
.setting-page {
    min-width: 1080px;
}
</style>
